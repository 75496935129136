<template>
  <b-card
      class="brandrewardorder-edit-wrapper"
  >
    <!-- form -->
    <b-form id="brandrewardorderForm" class="edit-form mt-2">
      <b-card header="基本信息">


        <b-row>

          <b-col md="4">
            <modal-select
                label="资金池"
                type="input"
                v-on:change="fromChildren($event,['reward_name','reward_id','recharge_remain','return_remain'])"
                :params="['reward_name','reward_id','recharge_remain','return_remain']"
                modalName="资金池"
                placeholder="点击选择资金池"
                v-model="brandrewardorder.reward_name"
            >
            </modal-select>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="请选择调整类型"
                label-for="reward_type"
                label-size="sm"
                class="mb-1 required"
            >
              <v-select
                  id="reward_type"
                  :options="getCodeOptions('reward_type').slice(0,4)"
                  class="select-size-sm"
                  v-model="brandrewardorder.reward_type"
              />
            </b-form-group>
          </b-col>
          <!--期初预充调整-->
          <b-col md="4" v-if="brandrewardorder.reward_type == null ? false:brandrewardorder.reward_type.value==1">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="期初预充调整"
                label-for="recharge_begin_change"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="recharge_begin_change"
                  size="sm"
                  type="number"
                  v-model="brandrewardorder.recharge_begin_change"
              />
            </b-form-group>
          </b-col>
          <!--期初返利调整-->
          <b-col md="4" v-if="brandrewardorder.reward_type == null ? false:brandrewardorder.reward_type.value==2">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="期初返利调整"
                label-for="return_begin_change"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="return_begin_change"
                  size="sm"
                  type="number"
                  v-model="brandrewardorder.return_begin_change"
              />
            </b-form-group>
          </b-col>
          <!--预充额度调整-->
          <b-col md="4" v-if="brandrewardorder.reward_type == null ? false:brandrewardorder.reward_type.value==3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="预充额度调整"
                label-for="recharge_amount_change"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="recharge_amount_change"
                  size="sm"
                  type="number"
                  v-model="brandrewardorder.recharge_amount_change"
              />
            </b-form-group>
          </b-col>
          <!--返利额度调整-->
          <b-col md="4" v-if="brandrewardorder.reward_type == null ? false:brandrewardorder.reward_type.value==4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="返利额度调整"
                label-for="return_amount_change"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="return_amount_change"
                  size="sm"
                  type="number"
                  v-model="brandrewardorder.return_amount_change"
              />
            </b-form-group>
          </b-col>
          <!--期初预充调整-->
          <b-col md="4" v-if="brandrewardorder.reward_type == null ? false:brandrewardorder.reward_type.value==1">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="预充剩余调整前："
                label-size="sm"
            >
              <b-form-input
                  size="sm"
                  type="number"
                  readonly
                  :value="this.brandrewardorder.recharge_remain"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="brandrewardorder.reward_type == null ? false:brandrewardorder.reward_type.value==1">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="预充剩余调整后："
                label-size="sm"
            >
              <b-form-input
                  size="sm"
                  type="number"
                  readonly
                  :value="addNumber(this.brandrewardorder.recharge_remain,brandrewardorder.recharge_begin_change)"
              />
            </b-form-group>
          </b-col>
          <!--期初返利调整-->
          <b-col md="4" v-if="brandrewardorder.reward_type == null ? false:brandrewardorder.reward_type.value==2">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="返利剩余调整前："
                label-size="sm"
            >
              <b-form-input
                  size="sm"
                  type="number"
                  readonly
                  :value="this.brandrewardorder.return_remain"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="brandrewardorder.reward_type == null ? false:brandrewardorder.reward_type.value==2">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="返利剩余调整后："
                label-size="sm"
            >
              <b-form-input
                  size="sm"
                  type="number"
                  readonly
                  :value="addNumber(this.brandrewardorder.return_remain,brandrewardorder.return_begin_change)"
              />
            </b-form-group>
          </b-col>
          <!--预充额度调整-->
          <b-col md="4" v-if="brandrewardorder.reward_type == null ? false:brandrewardorder.reward_type.value==3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="预充剩余调整前："
                label-size="sm"
            >
              <b-form-input
                  size="sm"
                  type="number"
                  readonly
                  :value="this.brandrewardorder.recharge_remain"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="brandrewardorder.reward_type == null ? false:brandrewardorder.reward_type.value==3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="预充剩余调整后："
                label-size="sm"
            >
              <b-form-input
                  size="sm"
                  type="number"
                  readonly
                  :value="addNumber(this.brandrewardorder.recharge_remain,brandrewardorder.recharge_amount_change)"
              />
            </b-form-group>
          </b-col>
          <!--返利额度调整-->
          <b-col md="4" v-if="brandrewardorder.reward_type == null ? false:brandrewardorder.reward_type.value==4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="返利剩余调整前："
                label-size="sm"
            >
              <b-form-input
                  size="sm"
                  type="number"
                  readonly
                  :value="this.brandrewardorder.return_remain"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="brandrewardorder.reward_type == null ? false:brandrewardorder.reward_type.value==4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="返利剩余调整后："
                label-size="sm"
            >
              <b-form-input
                  size="sm"
                  type="number"
                  readonly
                  :value="addNumber(this.brandrewardorder.return_remain,brandrewardorder.return_amount_change)"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="备注"
                label-for="memo"
                label-size="sm"
                class="mb-1"
            >
              <b-form-textarea
                  id="memo"
                  size="sm"
                  v-model="brandrewardorder.memo"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件上传"
                label-for="attachments"
                label-size="sm"
                :class="'mb-1'"
            >
              <attachment-upload v-if="brandrewardorder.loaded" :theme="'files'"
                                 :attachment_id="'attachments'"
                                 :id="brandrewardorder.attachments"
                                 :object_type="'brandrewardorder'"
                                 :object_id="brandrewardorder.archive_id"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>
        </b-row>


      </b-card>

      <b-col
          cols="12"
          class="mt-50"
      >
        <b-button
            variant="primary"
            class="mr-1"
            @click="save"
        >
          保存
        </b-button>
        <b-button
            variant="outline-secondary"
            @click="cancel"
        >
          取消
        </b-button>
      </b-col>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from "@/store";
import brandrewardorderStore from './brandrewardorderStore'
import {useToast} from 'vue-toastification/composition'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isNumber,addNumber} from '@core/utils/filter'
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import ModalSelect from "@/views/components/modal/ModalSelect";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    AttachmentUpload,
    ModalSelect,
  },
  data() {
    return {
      id: ref(0),
      brandrewardorder: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('brandrewardorder')) store.registerModule('brandrewardorder', brandrewardorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('brandrewardorder')) store.unregisterModule('brandrewardorder')
    })

    const edit = function () {
      store.dispatch('brandrewardorder/edit', {id: this.id}).then(res => {
        this.brandrewardorder = res.data.data
        let reward = this.brandrewardorder.ext == null ? null:this.brandrewardorder.ext.reward
        let archive = this.brandrewardorder.ext == null ? null:this.brandrewardorder.ext.archive
        if (archive != null) this.brandrewardorder.reward_name = archive.brand_name +' '+archive.area_name
        if (this.brandrewardorder.reward_type != null) this.brandrewardorder.reward_type = getCode('reward_type',this.brandrewardorder.reward_type)
        if (reward != null){
          this.brandrewardorder.recharge_remain = reward.recharge_remain
          this.brandrewardorder.return_remain = reward.return_remain
        }
      })
    }

    const view = function () {
      store.dispatch('brandrewardorder/view', {id: this.id}).then(res => {
        this.brandrewardorder = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      if (this.brandrewardorder.reward_name == null) {
        toast.error('请选择资金池')
        return false
      }
      if (this.brandrewardorder.reward_type == null) {
        toast.error('请选择调整类型')
        return false
      }
      if (this.brandrewardorder.reward_type.value == 1) {
        if(this.brandrewardorder.recharge_begin_change == null){
          toast.error('请填写期初预期调整')
          return false
        }else {
          this.brandrewardorder.return_begin_change = 0
          this.brandrewardorder.recharge_amount_change = 0
          this.brandrewardorder.return_amount_change = 0
        }
      }
      if (this.brandrewardorder.reward_type.value == 2) {
        if(this.brandrewardorder.return_begin_change == null){
          toast.error('请填写期初返利调整')
          return false
        }else {
          this.brandrewardorder.recharge_begin_change = 0
          this.brandrewardorder.recharge_amount_change = 0
          this.brandrewardorder.return_amount_change = 0
        }
      }
      if (this.brandrewardorder.reward_type.value == 3) {
        if(this.brandrewardorder.recharge_amount_change == null){
          toast.error('请填写预充额度调整')
          return false
        }else {
          this.brandrewardorder.return_begin_change = 0
          this.brandrewardorder.recharge_begin_change = 0
          this.brandrewardorder.return_amount_change = 0
        }
      }
      if (this.brandrewardorder.reward_type.value == 4) {
        if(this.brandrewardorder.return_amount_change == null){
          toast.error('请填写返利额度调整')
          return false
        }else {
          this.brandrewardorder.recharge_begin_change = 0
          this.brandrewardorder.return_begin_change = 0
          this.brandrewardorder.recharge_amount_change = 0
        }
      }
      this.brandrewardorder.rewardType = this.brandrewardorder.reward_type.value
      store.dispatch('brandrewardorder/save', this.brandrewardorder).then(res => {
        toast.success('数据已保存!')
        this.$router.push({name: 'apps-brandrewardorder-list'});
      })
    }

    const onUploaded = function (id, attachment, result) {
      this.brandrewardorder[id] = result
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.brandrewardorder[modal[i]] = params == null ? 0 : params[modal[i]]
      }
      this.$forceUpdate()
    }


    return {
      edit,
      view,
      cancel,
      save,
      onUploaded,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      fromChildren,
      isNumber,
      addNumber,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
